import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import { useStaticQuery, graphql } from "gatsby"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"

import Header from "../header"
import Footer from "../footer"

const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1412 },
  },
  typography: {
    fontSize: 25,
    fontFamily: ["Poppins", "sans-serif"].join(", "),
    body1: {
      fontSize: 25,
    },
    body2: {
      fontSize: 20,
    },
    h1: {
      fontSize: 48,
      fontWeight: "900",
      fontFamily: ["Poppins", "sans-serif"].join(", "),
      textTransform: "uppercase",
    },
    h2: {
      fontSize: 35,
      fontWeight: "600",
      fontFamily: ["Poppins", "sans-serif"].join(", "),
    },
    h3: {
      fontSize: 35,
      fontWeight: "400",
      fontFamily: ["Poppins", "sans-serif"].join(", "),
    },
    h4: {
      fontSize: 30,
      fontWeight: "600",
      fontFamily: ["Poppins", "sans-serif"].join(", "),
    },
    // h5: {
    //   fontFamily: ["Oswald", "sans-serif"].join(", "),
    // },
  },
  palette: {
    primary: {
      main: "#363654",
    },
    secondary: {
      main: "#FC9E11",
    },
    text: {
      primary: "#363654",
      secondary: "#fff",
      disabled: "#333399",
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "inherit",
        transition: "background-color 200ms linear",
      },
    },
    MuiPaper: {
      elevation0: {
        color: "#8771ef !important",
        backgroundColor: "transparent",
      },
      elevation4: {
        color: "#fff !important",
        backgroundColor: "#8771ef !important",
      },
    },
    MuiButton: {
      contained: {
        color: "#fff",
        padding: "15px 50px",
        fontSize: "35px",
        boxShadow: "0px 12px 10px rgb(0, 0, 0,40%)",
        borderRadius: 50,
        textTransform: "capitalize",
        backgroundColor: "#FC9E11",
        "&:hover": {
          backgroundColor: "#FC9E11",
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: "#FC9E11",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "16px",
        "@media (max-width: 599px)": {
          fontSize: "14px",
        },
      },
      h1: {
        "@media (max-width: 599px)": {
          fontSize: "25px",
        },
      },
      colorTextSecondary: {
        "@media (max-width: 599px)": {
          fontSize: "25px",
        },
      },
      h4: {
        "@media (max-width: 599px)": {
          fontSize: "18px",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#fff !important",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*": {
          wordBreak: "break-word",
        },
        html: {
          WebkitFontSmoothing: "auto",
        },
        table: {
          width: "100%",
          tableLayout: "fixed",
          borderCollapse: "collapse",
          "& td": {
            border: `1px solid #ddd`,
            padding: 8,
          },
        },
        ".slick-list": {
          boxSizing: "content-box",
        },
        ".slider-recomenndation  .slick-slide": {
          flex: "0 0 100vw",
          height: "initial",
          display: "flex",
          maxWidth: "100vw",
          "@media (min-width: 601px)": {
            maxWidth: "660px",
          },
          "& > div": {
            flex: "1 1 auto",
            display: "flex",
          },
        },
        ".slider-offer .slick-slide": {
          flex: "0 0 100vw",
          height: "initial",
          display: "flex",
          maxWidth: "calc(100vw - 50px)",
          "@media (min-width: 601px)": {
            maxWidth: "660px",
          },
          "& > div": {
            flex: "1 1 auto",
            display: "flex",
          },
        },

        ".slick-track": {
          display: "flex",
          padding: "4px 0",
        },
        ".slick-arrow": {},
        ".slick-prev": {
          left: 50,
        },
        ".slick-next": {
          right: 50,
        },
        ".active-link": {
          color: "#FC9E11 !important",
        },
      },
    },
  },
})

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header {...props} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{props.children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
