import React from "react"
import get from "lodash/get"
import scrollTo from "gatsby-plugin-smoothscroll"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MuiSwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { StaticImage } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby"

import { makeStyles, withStyles } from "@material-ui/core/styles"

const SwipeableDrawer = withStyles({
  paper: {
    color: "#fff",
    padding: 100,
    backgroundColor: "#8771ef",
  },
})(MuiSwipeableDrawer)

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  link: {
    color: "#fff",
    display: "block",
    padding: "12px 0",
    textDecoration: "none",
    "& span": {
      fontWeight: "600",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  closeIcon: {
    top: 30,
    color: "#fff",
    width: 60,
    right: 30,
    height: 60,
    position: "absolute",
  },
  bgImage: {
    top: 0,
    right: "-700px",
    width: 800,
    bottom: 0,
    height: "auto",
    margin: "auto",
    zIndex: "-1",
    position: "absolute",
    maxHeight: 800,
  },
})

export default function TemporaryDrawer(props) {
  const classes = useStyles()
  const pathName = get(props, "location.pathname")
  const isHome = pathName === "/"

  const handleLinkClick = anchor => {
    if (!isHome) {
      return navigate(`/`)
    }
    props.setDrawer(false)
    setTimeout(() => {
      return scrollTo(`#${anchor}`, "start")
    }, 300)
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={props.drawer}
      onOpen={() => props.setDrawer(true)}
      onClose={() => props.setDrawer(false)}
    >
      <>
        <StaticImage
          src="../../images/dots.png"
          alt="background image"
          placeholder="none"
          className={classes.bgImage}
        />
        <IconButton
          aria-label="close"
          onClick={() => props.setDrawer(false)}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <nav>
          <Link
            to="/"
            onClick={() => handleLinkClick("home")}
            className={classes.link}
          >
            <Typography color="textSecondary" variant="h3" component="span">
              Home
            </Typography>
          </Link>
          <Link to="/" className={classes.link}>
            <Typography
              color="textSecondary"
              variant="h3"
              onClick={() => handleLinkClick("onas")}
              component="span"
            >
              O nas
            </Typography>
          </Link>
          <Link
            to="/"
            onClick={() => handleLinkClick("oferta")}
            className={classes.link}
          >
            <Typography color="textSecondary" variant="h3" component="span">
              Oferta
            </Typography>
          </Link>
          <Link
            to="/"
            onClick={() => handleLinkClick("blog")}
            className={classes.link}
          >
            <Typography color="textSecondary" variant="h3" component="span">
              Blog
            </Typography>
          </Link>
          <Link
            to="/"
            onClick={() => handleLinkClick("kontakt")}
            className={classes.link}
          >
            <Typography color="textSecondary" variant="h3" component="span">
              Kontakt
            </Typography>
          </Link>
        </nav>
      </>
    </SwipeableDrawer>
  )
}
