import React from "react"
import get from "lodash/get"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CssBaseline from "@material-ui/core/CssBaseline"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

import { makeStyles } from "@material-ui/core/styles"
import { Link, navigate } from "gatsby"

import Logo from "../../images/logo.svg"
import MenuIcon from "../../images/menu.svg"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 5px",
    },
  },
  menuButtonContainer: {
    height: `${theme.spacing(7)}px`,
    flex: `0 0 ${theme.spacing(7)}px`,
  },
  menuButton: {
    fill: "#000",
    width: 30,
    cursor: "pointer",
    display: "initial",
    "@media (min-width: 1401px)": {
      display: "none",
    },
  },
  navigation: {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  },
  link: {
    color: theme.palette.text.disabled,
    margin: "0px 50px",
    cursor: "pointer",
    display: "inline-block",
    fontWeight: "600",
    fontSize: "22px",
    transition: "color 200ms linear",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:hover:active": {
      color: theme.palette.secondary.main,
    },
    "@media (max-width: 1700px)": {
      margin: "0px 50px",
    },
    "@media (max-width: 1550px)": {
      margin: "0px 40px",
    },
    "@media (max-width: 1400px)": {
      display: "none",
    },
  },
  logo: {
    width: 215,
    height: 54,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: 120,
      height: 30,
    },
  },
}))

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const LINKS = ["home", "onas", "oferta", "blog", "kontakt"]

export default function CustomAppBar(props) {
  const classes = useStyles()
  const pathName = get(props, "location.pathname")
  const isHome = pathName === "/"
  const handleClick = () => {
    props.setDrawer(drawer => !drawer)
  }

  const handleLinkClick = anchor => {
    if (!isHome) {
      const slug = anchor ? `/#${anchor}` : `/`
      return navigate(slug)
    }

    return scrollTo(`#${anchor}`, "start")
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed">
          <Toolbar>
            <Box component="div" className={classes.container}>
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
              <nav className={classes.navigation}>
                <Scrollspy
                  items={LINKS}
                  currentClassName="active-link"
                  offset={-50}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.link}
                    onClick={() => handleLinkClick("home")}
                  >
                    Home
                  </Typography>

                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.link}
                    onClick={() => handleLinkClick("onas")}
                  >
                    O nas
                  </Typography>

                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.link}
                    onClick={() => handleLinkClick("oferta")}
                  >
                    Oferta
                  </Typography>

                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.link}
                    onClick={() => handleLinkClick("blog")}
                  >
                    Blog
                  </Typography>

                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.link}
                    onClick={() => handleLinkClick("kontakt")}
                  >
                    Kontakt
                  </Typography>
                </Scrollspy>
              </nav>
            </Box>

            <IconButton
              edge="start"
              size="small"
              onClick={handleClick}
              className={classes.menuButton}
              aria-label="menu"
            >
              <MenuIcon aria-label="menu" className={classes.menuButton} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  )
}
