import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2.7),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  link: {
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  footerText: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
  projectLink: {
    marginRight: theme.spacing(1),
  },
  divider: {
    height: 20,
    margin: "0 10px",
    background: "#fff",
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <Box className={classes.footer}>
      <Container maxWidth="xl" className={classes.container}>
        <Box component="span" mr={1} whiteSpace="nowrap">
          <Link to="/" className={classes.link}>
            <Typography variant="body2" className={classes.footerText}>
              kancelariadenaro.com
            </Typography>
          </Link>
        </Box>

        <Box component="div" whiteSpace="nowrap" m={0}>
          <Typography variant="body2" className={classes.footerText}>
            © - Wszelkie prawa zastrzeżone
          </Typography>
        </Box>

        <Box component="span" whiteSpace="nowrap">
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
        </Box>

        <Box component="span" whiteSpace="nowrap">
          <Typography variant="body2" className={classes.footerText}>
            <Link to="/polityka-prywatnosci" className={classes.link}>
              Polityka prywatności
            </Link>
          </Typography>
        </Box>

        <Box component="span" whiteSpace="nowrap">
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
        </Box>

        <Box component="span">
          <Typography
            variant="body2"
            className={cx(classes.footerText, classes.projectLink)}
          >
            projekt:
          </Typography>
        </Box>

        <a
          rel="noopener noreferrer"
          href="//mazak.studio"
          target="_blank"
          className={classes.link}
        >
          <Typography variant="body2" className={classes.footerText}>
            mazak.studio
          </Typography>
        </a>
      </Container>
    </Box>
  )
}
